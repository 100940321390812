module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.11.0_gatsby@5.11.0_graphql@16.6.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ViatiTracker","short_name":"VT","description":"Registro de facturas y gastos para viajes de trabajo","start_url":"/","background_color":"#FFF","theme_color":"#4C9FC1","display":"standalone","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b7a0c1f76041601418c209f23a8db6bf"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.11.0_gatsby@5.11.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.11.0_babel-eslint@10.1.0_eslint-import-resolver-typescript@3.5.5_react-dom@18.2.0_react@18.2.0_typescript@5.0.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
